@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: hsl(220, 13%, 18%); */
  background-color: rgb(170, 162, 153);
  min-height: 100vh;
}

* {
  font-family: 'Roboto', sans-serif;
}
