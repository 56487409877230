.projects__header {
  color: hsl(220, 13%, 18%);
  font-size: 200%;
  text-align: center;
}
.form__container {
  display: flex;
  flex-direction: column;
  /* margin-top: 20vh; */
  background-color: hsl(220, 13%, 18%);
  padding: 3rem;
  border-radius: 1rem;
}

.contact__title {
  font-size: 200%;
}
.contactForm {
  display: flex;
  flex-direction: column;
  color: lightgrey;
  font-size: 120%;
}
.contact__submit {
  background-color: rgb(170, 162, 153);
  color: hsl(220, 13%, 18%);
  font-size: 100%;
}
.contact__submit:hover {
  cursor: pointer;
}

.contact__title {
  color: hsl(220, 13%, 18%);
}

.landing__page {
  font-size: 120%;
}

.confirmation {
  font-size: 120%;
  padding-top: 5rem;
}

.landing__blurb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 140%;
  min-height: 50vh;
  width: auto;
  text-align: left;
  color: lightgrey;
  background-color: hsl(220, 13%, 18%);
  padding: 1rem 3rem;
  border-radius: 1rem;
}
.about__header {
  font-size: 200%;
}
.about__me__content {
  margin-bottom: 1rem;
  padding: 1rem 3rem;
  background-color: hsl(220, 13%, 18%);
  min-height: 20vh;
  border-radius: 1rem;
  text-align: left;
  color: lightgrey;
  font-size: 120%;
  line-height: 140%;
}

.about__link__styling {
  color: #fff;
}
@media all and (min-width: 900px) {
  .landing__page {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .landing__header {
    font-size: 200%;
  }
  .landing__blurb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 140%;

    align-self: center;
    height: 50vh;
    width: 80%;
    text-align: left;
    color: lightgrey;
    background-color: hsl(220, 13%, 18%);
    padding: 3rem;
    border-radius: 1rem;
  }
  .blurb {
    line-height: 1.6rem;
  }

  .contact__page {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .form__container {
    height: 50vh;
    width: 80%;
    align-self: center;
    justify-content: flex-start;
  }

  .contact__label {
    margin-top: 2rem;
  }

  .contact__input {
    height: 2rem;
    font-size: 100%;
  }

  .contact__message {
    height: 4rem;
    font-size: 100%;
  }

  .backlog__container {
    display: flex;
    flex-direction: column;
  }
  .backlog__header {
    margin: 0;
  }
  .project__backlog__item {
    list-style: square;
  }
}

@media all and (min-width: 992px) {
  .confirmation {
    padding-top: 20rem;
  }
}
