.project__summary {
  color: rgb(218, 218, 218);
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 1rem;
  overflow: hidden;
  font-size: 120%;
}

.project__summary__item {
  margin-bottom: 1rem;
  background-color: hsl(220, 13%, 18%);
  min-height: 20vh;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
}

.project__title {
  margin: 0;
  text-align: left;
}

.title__stack {
  display: flex;
  flex-direction: column;
}

.project__stack {
  margin: 0.1rem 0;
}

.project__link {
  color: lightgrey;
  cursor: pointer;
  text-decoration: underline;
  list-style: none;
  font-size: 90%;
}

.project__summary button {
  width: auto;
  margin-bottom: 1rem;
  font-size: 100%;
}

.compliment__button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.mad__love {
  margin: 0;
  padding: 0.5rem 0.5rem;
  font-size: inherit;
}

.compliment__button__button {
  align-self: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(218, 218, 218, 0.863);
}

.compliment__button__button:hover {
  cursor: pointer;
}

.repo__links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  padding-bottom: 1rem;
}

@media all and (min-width: 900px) {
  .project__summary {
    display: flex;
    flex-direction: column;
    text-align: left;
    /* margin-top: 1rem; */
    padding: 0 1rem;
  }

  .project__title {
    text-align: left;
  }

  .project__summary__item {
    padding: 0 1rem;
  }

  .project__summary button {
    margin-bottom: 1rem;
  }

  .compliment__button {
    display: flex;
    justify-content: space-between;
  }

  .smaller {
    font-size: 95%;
  }
}

@media all and (min-width: 1400px) {
  .title__stack {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
