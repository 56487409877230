@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: hsl(220, 13%, 18%); */
  background-color: rgb(170, 162, 153);
  min-height: 100vh;
}

* {
  font-family: 'Roboto', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  color: hsl(220, 13%, 18%);
  min-height: 100vh;
  padding: 1rem 1.5rem;
}

.top__hr {
  display: none;
}

.col__2 {
  display: flex;
  flex-direction: column;
  text-align: center;
}

/* li {
  list-style: none;
} */

.media__header {
  display: flex;
}

@media all and (min-width: 992px) {
  .App {
    display: flex;
    flex-direction: row;
    color: hsl(220, 13%, 18%);
    min-height: 100vh;
  }

  .top__hr {
    background-color: rgba(128, 123, 123, 0.383);
    color: rgba(128, 123, 123, 0.383);
    -webkit-filter: drop-shadow(0rem 0rem 0.1rem rgba(5, 9, 17, 0.192));
            filter: drop-shadow(0rem 0rem 0.1rem rgba(5, 9, 17, 0.192));
    border: none;
    height: 0.09rem;
    width: 99%;
    align-content: left;
  }

  .col__1 {
    padding: 0 2rem;
    width: 30vh;
  }

  .col__2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 5rem;
    width: 53vh;
    padding: 0 2rem;
    border-right: solid 0.1rem rgba(128, 123, 123, 0.383);
    border-left: solid 0.1rem rgba(128, 123, 123, 0.383);
    flex-shrink: 0;
  }

  li {
    list-style: none;
  }

  .side__bar h1 {
    display: flex;
    flex-direction: column;
  }
}

@media all and (min-width: 1050px) {
  .col__2 {
    width: 65vh;
  }
}
@media all and (min-width: 1200px) {
  .col__2 {
    width: 75vh;
  }
}
@media all and (min-width: 1300px) {
  .col__2 {
    width: 85vh;
  }
}

@media all and (min-width: 1400px) {
  .col__2 {
    width: 100vh;
  }
}

.project__summary {
  color: rgb(218, 218, 218);
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 1rem;
  overflow: hidden;
  font-size: 120%;
}

.project__summary__item {
  margin-bottom: 1rem;
  background-color: hsl(220, 13%, 18%);
  min-height: 20vh;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
}

.project__title {
  margin: 0;
  text-align: left;
}

.title__stack {
  display: flex;
  flex-direction: column;
}

.project__stack {
  margin: 0.1rem 0;
}

.project__link {
  color: lightgrey;
  cursor: pointer;
  text-decoration: underline;
  list-style: none;
  font-size: 90%;
}

.project__summary button {
  width: auto;
  margin-bottom: 1rem;
  font-size: 100%;
}

.compliment__button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.mad__love {
  margin: 0;
  padding: 0.5rem 0.5rem;
  font-size: inherit;
}

.compliment__button__button {
  align-self: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(218, 218, 218, 0.863);
}

.compliment__button__button:hover {
  cursor: pointer;
}

.repo__links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  padding-bottom: 1rem;
}

@media all and (min-width: 900px) {
  .project__summary {
    display: flex;
    flex-direction: column;
    text-align: left;
    /* margin-top: 1rem; */
    padding: 0 1rem;
  }

  .project__title {
    text-align: left;
  }

  .project__summary__item {
    padding: 0 1rem;
  }

  .project__summary button {
    margin-bottom: 1rem;
  }

  .compliment__button {
    display: flex;
    justify-content: space-between;
  }

  .smaller {
    font-size: 95%;
  }
}

@media all and (min-width: 1400px) {
  .title__stack {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.projects__header {
  color: hsl(220, 13%, 18%);
  font-size: 200%;
  text-align: center;
}
.form__container {
  display: flex;
  flex-direction: column;
  /* margin-top: 20vh; */
  background-color: hsl(220, 13%, 18%);
  padding: 3rem;
  border-radius: 1rem;
}

.contact__title {
  font-size: 200%;
}
.contactForm {
  display: flex;
  flex-direction: column;
  color: lightgrey;
  font-size: 120%;
}
.contact__submit {
  background-color: rgb(170, 162, 153);
  color: hsl(220, 13%, 18%);
  font-size: 100%;
}
.contact__submit:hover {
  cursor: pointer;
}

.contact__title {
  color: hsl(220, 13%, 18%);
}

.landing__page {
  font-size: 120%;
}

.confirmation {
  font-size: 120%;
  padding-top: 5rem;
}

.landing__blurb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 140%;
  min-height: 50vh;
  width: auto;
  text-align: left;
  color: lightgrey;
  background-color: hsl(220, 13%, 18%);
  padding: 1rem 3rem;
  border-radius: 1rem;
}
.about__header {
  font-size: 200%;
}
.about__me__content {
  margin-bottom: 1rem;
  padding: 1rem 3rem;
  background-color: hsl(220, 13%, 18%);
  min-height: 20vh;
  border-radius: 1rem;
  text-align: left;
  color: lightgrey;
  font-size: 120%;
  line-height: 140%;
}

.about__link__styling {
  color: #fff;
}
@media all and (min-width: 900px) {
  .landing__page {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .landing__header {
    font-size: 200%;
  }
  .landing__blurb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 140%;

    align-self: center;
    height: 50vh;
    width: 80%;
    text-align: left;
    color: lightgrey;
    background-color: hsl(220, 13%, 18%);
    padding: 3rem;
    border-radius: 1rem;
  }
  .blurb {
    line-height: 1.6rem;
  }

  .contact__page {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .form__container {
    height: 50vh;
    width: 80%;
    align-self: center;
    justify-content: flex-start;
  }

  .contact__label {
    margin-top: 2rem;
  }

  .contact__input {
    height: 2rem;
    font-size: 100%;
  }

  .contact__message {
    height: 4rem;
    font-size: 100%;
  }

  .backlog__container {
    display: flex;
    flex-direction: column;
  }
  .backlog__header {
    margin: 0;
  }
  .project__backlog__item {
    list-style: square;
  }
}

@media all and (min-width: 992px) {
  .confirmation {
    padding-top: 20rem;
  }
}

.blog__header {
  font-size: 200%;
}

.blog__entry {
  margin-bottom: 1rem;
  padding: 1rem 3rem;
  background-color: hsl(220, 13%, 18%);
  min-height: 20vh;
  border-radius: 1rem;
  text-align: left;
  color: lightgrey;
  font-size: 120%;
  line-height: 140%;
}

.emphasis {
  font-size: 125%;
}

.entry__header {
  font-size: 150%;
  font-style: italic;
}

.header__main {
  display: flex;
  flex-direction: row;
}

.nav__text {
  font-style: none;
  text-decoration: none;
}

.nav__item {
  list-style: none;
}
.header__link:link,
.header__link:visited,
.header__link:active {
  text-decoration: none;
}

.nav__text:link,
.nav__text:visited,
.nav__text:active,
.media__link:link,
.media__link:visited {
  text-decoration: none;
  color: hsl(220, 13%, 18%);
  font-size: 120%;
}

.tucker__photo {
  padding: 1rem 0;
  border-radius: 50%;
}

.githubLabel {
  margin-top: 0.5rem;
}
.codewarsLabel {
  margin-top: 0.5rem;
}

.linkedinLabel {
  margin-top: 0.9rem;
}
.header__nav {
  display: flex;
  justify-content: center;
  font-size: 120%;
  margin: 0;
  padding: 0;
}

.nav__item {
  margin-bottom: 1rem;
  padding-right: 1rem;
}
.media__header {
  display: flex;
  flex-direction: column;
  border-bottom: solid 0.01rem rgba(128, 123, 123, 0.383);
}
.media__list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 0;
  padding: 0;
}

.media__item {
  display: flex;
  padding-top: 0.5rem;
  flex-direction: column;
  justify-content: center;
}

.media__item:last-child {
  margin-right: 0;
}

.media__link {
  display: flex;
  flex-direction: column;
  height: 100px;
}

.header__title {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 1rem 1rem 1rem 0;
}

.header__word1 {
  color: rgb(173, 114, 3);
}
.header__word2 {
  color: #2f5233;
}
.header__word3 {
  color: rgb(173, 114, 3);
}
.header__word4 {
  color: #2f5233;
}

.imageLabel {
  padding: auto;
}
@media all and (min-width: 900px) {
  .header__main {
    display: flex;
    flex-direction: column;
  }

  .media__header {
    flex-direction: column;
    border-bottom: none;
    justify-content: center;
  }

  .media__list {
    margin-left: 0;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 1rem 0;
    border-bottom: solid 0.01rem rgba(128, 123, 123, 0.383);
    border-top: solid 0.01rem rgba(128, 123, 123, 0.383);
  }

  .media__item {
    justify-content: center;
    margin-right: 2rem;
  }

  .media__link {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }

  .header__title {
    padding-bottom: 1rem;
  }
}

