.blog__header {
  font-size: 200%;
}

.blog__entry {
  margin-bottom: 1rem;
  padding: 1rem 3rem;
  background-color: hsl(220, 13%, 18%);
  min-height: 20vh;
  border-radius: 1rem;
  text-align: left;
  color: lightgrey;
  font-size: 120%;
  line-height: 140%;
}

.emphasis {
  font-size: 125%;
}

.entry__header {
  font-size: 150%;
  font-style: italic;
}
