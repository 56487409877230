.App {
  display: flex;
  flex-direction: column;
  color: hsl(220, 13%, 18%);
  min-height: 100vh;
  padding: 1rem 1.5rem;
}

.top__hr {
  display: none;
}

.col__2 {
  display: flex;
  flex-direction: column;
  text-align: center;
}

/* li {
  list-style: none;
} */

.media__header {
  display: flex;
}

@media all and (min-width: 992px) {
  .App {
    display: flex;
    flex-direction: row;
    color: hsl(220, 13%, 18%);
    min-height: 100vh;
  }

  .top__hr {
    background-color: rgba(128, 123, 123, 0.383);
    color: rgba(128, 123, 123, 0.383);
    filter: drop-shadow(0rem 0rem 0.1rem rgba(5, 9, 17, 0.192));
    border: none;
    height: 0.09rem;
    width: 99%;
    align-content: left;
  }

  .col__1 {
    padding: 0 2rem;
    width: 30vh;
  }

  .col__2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 5rem;
    width: 53vh;
    padding: 0 2rem;
    border-right: solid 0.1rem rgba(128, 123, 123, 0.383);
    border-left: solid 0.1rem rgba(128, 123, 123, 0.383);
    flex-shrink: 0;
  }

  li {
    list-style: none;
  }

  .side__bar h1 {
    display: flex;
    flex-direction: column;
  }
}

@media all and (min-width: 1050px) {
  .col__2 {
    width: 65vh;
  }
}
@media all and (min-width: 1200px) {
  .col__2 {
    width: 75vh;
  }
}
@media all and (min-width: 1300px) {
  .col__2 {
    width: 85vh;
  }
}

@media all and (min-width: 1400px) {
  .col__2 {
    width: 100vh;
  }
}
