.header__main {
  display: flex;
  flex-direction: row;
}

.nav__text {
  font-style: none;
  text-decoration: none;
}

.nav__item {
  list-style: none;
}
.header__link:link,
.header__link:visited,
.header__link:active {
  text-decoration: none;
}

.nav__text:link,
.nav__text:visited,
.nav__text:active,
.media__link:link,
.media__link:visited {
  text-decoration: none;
  color: hsl(220, 13%, 18%);
  font-size: 120%;
}

.tucker__photo {
  padding: 1rem 0;
  border-radius: 50%;
}

.githubLabel {
  margin-top: 0.5rem;
}
.codewarsLabel {
  margin-top: 0.5rem;
}

.linkedinLabel {
  margin-top: 0.9rem;
}
.header__nav {
  display: flex;
  justify-content: center;
  font-size: 120%;
  margin: 0;
  padding: 0;
}

.nav__item {
  margin-bottom: 1rem;
  padding-right: 1rem;
}
.media__header {
  display: flex;
  flex-direction: column;
  border-bottom: solid 0.01rem rgba(128, 123, 123, 0.383);
}
.media__list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 0;
  padding: 0;
}

.media__item {
  display: flex;
  padding-top: 0.5rem;
  flex-direction: column;
  justify-content: center;
}

.media__item:last-child {
  margin-right: 0;
}

.media__link {
  display: flex;
  flex-direction: column;
  height: 100px;
}

.header__title {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 1rem 1rem 1rem 0;
}

.header__word1 {
  color: rgb(173, 114, 3);
}
.header__word2 {
  color: #2f5233;
}
.header__word3 {
  color: rgb(173, 114, 3);
}
.header__word4 {
  color: #2f5233;
}

.imageLabel {
  padding: auto;
}
@media all and (min-width: 900px) {
  .header__main {
    display: flex;
    flex-direction: column;
  }

  .media__header {
    flex-direction: column;
    border-bottom: none;
    justify-content: center;
  }

  .media__list {
    margin-left: 0;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 1rem 0;
    border-bottom: solid 0.01rem rgba(128, 123, 123, 0.383);
    border-top: solid 0.01rem rgba(128, 123, 123, 0.383);
  }

  .media__item {
    justify-content: center;
    margin-right: 2rem;
  }

  .media__link {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }

  .header__title {
    padding-bottom: 1rem;
  }
}
